import React from "react";
import Layout from "../components/Layout";

// Utilities
import kebabCase from "lodash/kebabCase";

// Components
import { Link, graphql } from "gatsby";
import Seo from "../components/Seo";

const SpecialismPage = ({
  data: {
    allContentfulStudent: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <div>
      <Seo title={`Kingston School of Art Foundation 2021 • Specialisms`} />
      <div>
        <h1>Specialisms</h1>
        <ul>
          {group.map((specialism) => (
            <li key={specialism.fieldValue}>
              <Link to={`/specialism/${kebabCase(specialism.fieldValue)}/`}>
                {specialism.fieldValue} ({specialism.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </Layout>
);

export default SpecialismPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulStudent {
      group(field: specialism) {
        fieldValue
        totalCount
      }
    }
  }
`;
